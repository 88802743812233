<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import {
  IonApp, IonRouterOutlet,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  mounted() {
    this.setGeneralParameters();

    this.setGoogleTagManager();

    this.setClarity();
  },
  computed: {
    ...mapState(['googleTagManager', 'clarityKey']),
  },
  methods: {
    ...mapActions(['getGeneralParameters']),
    setGeneralParameters() {
      this.getGeneralParameters();
    },
    setGoogleTagManager() {
      if (this.googleTagManager) {
        const gtmScript = document.createElement('script');
        gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${this.googleTagManager}');`;
        document.head.appendChild(gtmScript);

        const gtmNoScript = document.createElement('noscript');
        gtmNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.googleTagManager}"height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.appendChild(gtmNoScript);
      }
    },
    setClarity() {
      if (this.clarityKey) {
        const clarityScript = document.createElement('script');
        clarityScript.innerHTML = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "${this.clarityKey}");`;
        document.head.appendChild(clarityScript);
      }
    },
  },
});
</script>
