import { createRouter, createWebHistory } from '@ionic/vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import BackLayout from '../layouts/BackLayout.vue';
import ExcursionLayout from '../layouts/ExcursionLayout.vue';

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/HomePage.vue'),
      },
    ],
  },
  {
    path: '/404',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/ErrorPage.vue'),
      },
    ],
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/PrivacyPage.vue'),
      },
    ],
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and conditions',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/TermsAndConditionsPage.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: BackLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/LoginPage.vue'),
      },
    ],
  },
  {
    path: '/excursion/:excursionID',
    name: 'Excursion',
    component: ExcursionLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/ExcursionPage.vue'),
      },
      {
        path: 'book',
        component: () => import('../pages/ExcursionBookPage.vue'),
      },
    ],
  },
  {
    path: '/bookings/',
    name: 'Bookings',
    component: ExcursionLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/BookingsPage.vue'),
      },
      {
        path: 'print',
        component: () => import('../pages/BookingPrintPage.vue'),
      },
      {
        path: 'scan',
        component: () => import('../pages/BookingScanPage.vue'),
      },
      {
        path: 'failure',
        component: () => import('../pages/BookingFailurePage.vue'),
      },
    ],
  },
  {
    path: '/bookings/:bookingID',
    name: 'Booking',
    component: ExcursionLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/BookingPage.vue'),
      },
      {
        path: '/:bookingID',
        component: () => import('../pages/BookingPage.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
