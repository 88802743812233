<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="white">
        <ion-buttons slot="start" id="sidenav-toggle">
          <ion-menu-button color="medium" menu="sidenav"></ion-menu-button>
          <a
            :href="logoLink">
            <img
              class="hidden-sm-down"
              :height="150"
              :alt="generalParameters.DetailText1"
              :src="generalParameters.Logo"/>
            <img
              class="hidden-sm-up"
              :height="80"
              :alt="generalParameters.DetailText1"
              :src="generalParameters.Logo"/>
          </a>
        </ion-buttons>

        <ion-title v-if="user && user.Salesperson">
          <ion-button fill="outline">
            {{ user.Salesperson }}
          </ion-button>
        </ion-title>

        <ion-buttons
          v-if="navbarLinks"
          slot="end"
          class="text-medium">
          <ion-button
            v-for="(link, name) in navbarLinks"
            :href="link"
            :key="name"
            style="font-size: 20px"
            class="ion-text-capitalize hidden-sm-down">
            {{ name }}
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-router-outlet style="overflow-y: scroll"></ion-router-outlet>
    </ion-content>

    <ion-menu side="start" menu-id="sidenav" content-id="sidenav-toggle">
      <ion-list>
        <ion-item href="/">
          {{ $t('nav.book-now') }}
        </ion-item>
        <ion-item href="/bookings">
          {{ $t('nav.bookings') }}
        </ion-item>
        <ion-item v-if="isPartner" href="/bookings/print">
          {{ $t('nav.find-ticket') }}
        </ion-item>
      </ion-list>
      <ion-card v-if="user" class="ion-padding">
        <ion-card-title>
          {{ user.UserName }}
        </ion-card-title>

        <ion-card-content>
          <ion-button @click="logout" fill="clear" expand="block">
            {{ $t('buttons.logout') }}
          </ion-button>
        </ion-card-content>
      </ion-card>

      <ion-button v-else fill="clear" expand="block" href="/login">
        {{ $t('buttons.login-partner') }}
      </ion-button>
    </ion-menu>

    <ion-toolbar>
      <ion-buttons class="text-medium">
        <ion-button
          v-for="(link, name) in footerLinks"
          target="_blank"
          :href="link"
          :key="name"
          class="ion-text-capitalize">
          {{ $t('nav.' + name) }}
        </ion-button>

        <ion-button
          v-for="(value, name) in companyInfo"
          :key="name"
          class="ion-text-capitalize">
          {{ $t('fields.' + name) }}:
          {{ value }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-page>
</template>

<script>
import {
  IonToolbar, IonContent, IonPage, IonMenu, IonCard, IonMenuButton, IonButtons, IonRouterOutlet,
  IonCardTitle, IonCardContent, IonButton, IonHeader, IonItem, IonList,
} from '@ionic/vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    IonToolbar,
    IonContent,
    IonPage,
    IonMenu,
    IonCard,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonMenuButton,
    IonButtons,
    IonRouterOutlet,
    IonHeader,
    IonItem,
    IonList,
  },
  data() {
    return {
      logoLink: process.env.VUE_APP_LOGO_LINK,
      footerLinks: {
        privacy: process.env.VUE_APP_PRIVACY_LINK,
        tos: process.env.VUE_APP_TERMS_LINK,
      },
      companyInfo: {
        vat: process.env.VUE_APP_COMPANY_VAT,
        companyName: process.env.VUE_APP_COMPANY_NAME,
      },
    };
  },
  computed: {
    ...mapState(['user', 'registrationToken', 'generalParameters']),
    ...mapGetters(['isPartner']),
    navbarLinks() {
      const links = process.env.VUE_APP_NAVBAR_LINKS
        ? JSON.parse(process.env.VUE_APP_NAVBAR_LINKS)
        : null;

      return links;
    },
  },
  methods: {
    ...mapActions(['logout', 'registerPushNotifications']),
  },
  mounted() {
    if (this.registrationToken && !this.isPartner) {
      this.registerPushNotifications();
    }
  },
};
</script>
