<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <slot slot="primary">
          <ion-buttons slot="start">
            <ion-back-button default-href="/" :icon="backIcon"></ion-back-button>
          </ion-buttons>
        </slot>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-router-outlet></ion-router-outlet>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonToolbar, IonContent, IonPage, IonBackButton, IonButtons, IonRouterOutlet, IonHeader,
} from '@ionic/vue';
import {
  chevronBackOutline as backIcon,
} from 'ionicons/icons';

export default {
  components: {
    IonToolbar,
    IonContent,
    IonPage,
    IonBackButton,
    IonButtons,
    IonRouterOutlet,
    IonHeader,
  },
  data() {
    return {
      backIcon,
    };
  },
};
</script>
