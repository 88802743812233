/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { createStore, createLogger } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { toastController, isPlatform } from '@ionic/vue';
import { Buffer } from 'buffer';
import { PushNotifications } from '@capacitor/push-notifications';
import { createI18n } from 'vue-i18n';
import router from '../router';
import api from '../services/api';
import printApi from '../services/print-api';
import SocketClient from '../services/socket.io.client';
import en from '../locales/en.json';

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
  },
});

const { t: $t, tc: $tc } = i18n.global;

const plugins = [createPersistedState()];

if (process.env.NODE_ENV !== 'production') {
  plugins.push(createLogger());
}

const initState = {
  user: null,
  bearerToken: null,
  generalParameters: {},
  isScanning: false,
  scanResult: null,
  registrationToken: null,
  vouchers: {},
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  googleTagManager: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
  clarityKey: process.env.VUE_APP_CLARITY_KEY,
  autoPaidTourop: process.env.VUE_APP_AUTO_PAID_TOUROP,
  locale: 'en',
};

const store = createStore({
  state() {
    return initState;
  },
  getters: {
    isLoggedIn(store) {
      return Boolean(store.user && store.user.UserId);
    },
    isPartner(store) {
      return Boolean(store.user && store.user.SalespCode);
    },
    canViewPastDates() {
      return process.env.VUE_APP_BOOKINGS_REPORT !== 'FUTURE';
    },
    b2bDisableFutureDates() {
      return process.env.VUE_APP_BOOKINGS_DISABLE_FUTURE_DATES;
    },
  },
  mutations: {
    SET_USER(state, user) {
      if (user && user[0]) {
        state.user = user && user[0];
      }
    },
    SET_GENERAL_PARAMETERS(state, generalParameters) {
      state.generalParameters = generalParameters;
    },
    SET_BEARER_TOKEN(state, token) {
      state.bearerToken = token;
    },
    SET_SCANNING_STATE(state, isScanning) {
      state.isScanning = isScanning;
    },
    SET_SCANNING_RESULT(state, result) {
      state.scanResult = result;
    },
    SET_VOUCHER(state, voucher) {
      const { BookingID } = voucher;

      state.vouchers[BookingID] = voucher;
    },
    SET_REGISTRATION_TOKEN(state, token) {
      state.registrationToken = token;
    },
    RESET_BEARER_TOKEN(state) {
      state.bearerToken = null;
    },
    LOGOUT_USER(state) {
      state.user = null;
    },
    CLEAR_APP_DATA(state) {
      Object.keys(state).forEach((key) => {
        state[key] = null;
      });

      Object.keys(initState).forEach((key) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    login({ commit }, data) {
      return api.login(data).then((user) => {
        if (user && user[0] && (user[0].SalespCode)) {
          commit('SET_USER', user);
        } else {
          throw new Error('invalid-user');
        }
      });
    },
    fetchUser({ commit, dispatch }, data) {
      return api.fetchUser(data).then((user) => {
        if (user && user[0] && (user[0].SalespCode)) {
          commit('SET_USER', user);
        } else {
          dispatch('logout');
          window.location.replace('/');
        }
      });
    },
    logout({ commit }) {
      document.cookie = 'sessionID=;path=/;Max-Age=-99999999;';
      commit('CLEAR_APP_DATA');
      window.location.replace('/');
    },
    setScanningState({ commit }, isScanning) {
      return commit('SET_SCANNING_STATE', isScanning);
    },
    setScanningResult({ commit }, scanningResult) {
      return commit('SET_SCANNING_RESULT', scanningResult);
    },
    getGeneralParameters({ commit }) {
      return api.getGeneralParameters().then(([generalParameters]) => {
        commit('SET_GENERAL_PARAMETERS', generalParameters);
      });
    },
    getExcursion(state, data) {
      return api.getExcursion(data);
    },
    informAvailability(state, data) {
      return SocketClient.informAvailability(data);
    },
    watchUpdatePax(state, callback) {
      return SocketClient.watchUpdatePax(callback);
    },
    watchMonitor() {
      return SocketClient.watchMonitor();
    },
    unwatchMonitor() {
      return SocketClient.unwatchMonitor();
    },
    watchDriver(state, driver) {
      return SocketClient.watchDriver(driver);
    },
    watchPickupChange(state, callback) {
      return SocketClient.watchPickupChange(callback);
    },
    onUpdatePax(state, callback) {
      return SocketClient.watchUpdatePax(callback);
    },
    watchDelay(state, callback) {
      return SocketClient.watchDelay(callback);
    },
    watchStopSales(state, callback) {
      return SocketClient.watchStopSales(callback);
    },
    getBarcode(state, bookNr) {
      return api.getBarcode(bookNr);
    },
    async storeVoucher({ commit, dispatch }, data) {
      const barcodesPromise = data.Excursions.map(({ BookingNumber }) => dispatch('getBarcode', BookingNumber)
        .then((data) => Buffer.from(data, 'binary').toString('base64')));

      await Promise.all(barcodesPromise).then((barcodes) => {
        data.Excursions = data.Excursions.map((excursion, i) => ({
          ...excursion,
          barcode: barcodes[i],
        }));
      });

      return commit('SET_VOUCHER', data);
    },
    getPickups(state, data) {
      return api.getExcursion(data).then(({ Pickups }) => Pickups.map((pickup) => ({
        ...pickup,
        text: pickup.Pickup,
        appendText: pickup.PickupTime,
        value: pickup.Id,
      })));
    },
    getTicketTypes(state, data) {
      return api.getTicketTypes(data).then((ticketTypes) => ticketTypes.map((ticketType) => ({
        ...ticketType,
        text: ticketType.Name,
        value: ticketType.Code,
      })));
    },
    getLanguages(state, data) {
      return api.getLanguages(data).then((languages) => languages.map((lang) => ({
        ...lang,
        text: lang.Name,
        value: lang.Code,
      })));
    },
    getExcursions(state, data) {
      return api.getExcursions(data);
    },
    getReservation(state, data) {
      return api.getReservation(data).then(([booking]) => booking);
    },
    getReservations(state, data) {
      return api.getReservations(data);
    },
    getReservationList(state, data) {
      return api.getReservationList(data).then(({
        data, grandTotal, pax, adults, children, infants,
      }) => ({
        data: data.map(({ Excursions, ...e }) => (Excursions && Excursions[0] ? {
          ...e,
          ...Excursions[0],
          TicketCombined: `${Excursions[0].TicketOrder}${Excursions[0].TicketNumber}`,
        } : e)),
        Pax: pax,
        Adults: adults,
        Children: children,
        Infants: infants,
        GrandTotal: grandTotal,
      }));
    },
    fetchPrice(state, data) {
      return api.fetchPrice(data);
    },
    fetchPickupExceptions(state, data) {
      return api.fetchPickupExceptions(data);
    },
    fetchTickets(state, data) {
      return api.fetchTickets(data);
    },
    fetchNextTicket(state, data) {
      return api.fetchNextTicket(data);
    },
    fetchDefaultSalesman(state, data) {
      return api.fetchDefaultSalesman(data);
    },
    fetchWaypays(state, data) {
      return api.fetchWaypays(data);
    },
    fetchPaymentClientToken(state, waypay) {
      return api.fetchPaymentClientToken(waypay);
    },
    sendEmail(state, data) {
      return printApi.sendEmail(data);
    },
    bookReservation({ dispatch }, data) {
      return api.bookReservation(data).then((result) => {
        dispatch('getReservation', {
          RESERVATIONS: [result.id],
        }).then((booking) => {
          if (booking?.Excursions[0]?.Driver) {
            dispatch('informAvailability', {
              ...data,
              ...result,
              DRIVER: booking.Excursions[0].Driver,
            });
          } else {
            api.getDriversInExcurs(data.EXCURSIONS[0]).then((drivers) => {
              dispatch('informAvailability', {
                ...data,
                ...result,
                DRIVERS: drivers.map(({ DriverCode }) => DriverCode),
              });
            });
          }
        });

        return result;
      }).catch(({ response }) => {
        if (response) {
          throw new Error(response.data);
        }

        toastController
          .create({
            message: $t('errorCodes.something-went-wrong'),
            color: 'danger',
            duration: 2000,
          }).then((toast) => toast.present());
      });
    },
    editReservations({ dispatch }, data) {
      return api.editReservations(data).then((result) => {
        dispatch('getReservation', {
          BOOKNR: data.BOOKNR,
        }).then((booking) => {
          if (booking?.Excursions[0]?.Driver) {
            dispatch('informAvailability', {
              ...data,
              ...result,
              DRIVER: booking.Excursions[0].Driver,
            });
          } else {
            dispatch('informAvailability', {
              ...data,
              ...result,
            });
          }
        });

        return result;
      }).catch(({ response }) => {
        if (response) {
          throw new Error(response.data);
        }

        toastController
          .create({
            message: $t('errorCodes.something-went-wrong'),
            color: 'danger',
            duration: 2000,
          }).then((toast) => toast.present());
      });
    },
    watchDriverPosition(state, callback) {
      return SocketClient.watchDriverPosition(callback);
    },
    async registerPushNotifications({ dispatch, commit }, excursion) {
      if (isPlatform('capacitor')) {
        PushNotifications.addListener('registration', (token) => {
          commit('SET_REGISTRATION_TOKEN', token.value);

          if (excursion?.BookingNumber) {
            dispatch('editReservations', {
              BOOKNR: [excursion.BookingNumber],
              REGISTRATION_TOKEN: token.value,
            });
          }
        });

        PushNotifications.addListener('registrationError', (err) => {
          console.error('Registration error: ', err.error);
        });

        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          throw new Error($t('errorCodes.user-denied-permissions'));
        }

        await PushNotifications.register();

        PushNotifications.addListener('pushNotificationReceived', ({ data }) => {
          const { type, value, bookingID } = data;

          let title;
          let color;
          let duration;
          const actions = [];

          switch (type) {
            case 'inform-delay':
              duration = 2000;
              color = 'warning';
              title = $tc('errorCodes.inform-delay', { minutes: value });
              break;
            case 'start-pickup':
              duration = 2000;
              color = 'success';
              title = $t('successCodes.pickup-has-been-started');
              actions.push({
                text: $t('buttons.track-driver'),
                handler: () => {
                  if (bookingID) {
                    router.push(`/bookings/${bookingID}`);
                  }
                },
              });
              break;
            case 'arrival':
              duration = 2000;
              color = 'success';
              title = $t('successCodes.your-driver-has-been-arrived');
              actions.push({
                text: $t('buttons.track-driver'),
                handler: () => {
                  if (bookingID) {
                    router.push(`/bookings/${bookingID}`);
                  }
                },
              });
              break;
            case 'no-show':
              duration = 2000;
              title = $t('errorCodes.no-show');
              color = 'danger';
              break;
            default:
              duration = 2000;
          }

          if (title) {
            toastController
              .create({
                message: title,
                color,
                duration,
              }).then((toast) => toast.present());
          }
        });

        PushNotifications.addListener('pushNotificationActionPerformed', ({ notification, actionId }) => {
          const { bookingID } = notification.data;

          switch (actionId) {
            case 'tap':
              if (bookingID) {
                router.push(`/bookings/${bookingID}`);
              }
              break;
            default:
          }
        });
      }
    },
    getDeliveredNotifications() {
      return PushNotifications.getDeliveredNotifications();
    },
    setBearerToken({ commit }, token) {
      return commit('SET_BEARER_TOKEN', token);
    },
    resetBearerToken({ commit }, token) {
      return commit('RESET_BEARER_TOKEN', token);
    },
  },
  plugins,
});

export default store;
