import axios from 'axios';
import moment from 'moment';
import TokenService from './oauth';

class API {
  constructor() {
    this.ip = process.env.VUE_APP_MULTI_API_IP;
    axios.defaults.baseURL = process.env.VUE_APP_MULTI_API_IP;

    this.token = null;

    this.request = axios;

    this.mountRequestInterceptor();
    this.mount401Interceptor();
  }

  async setHeader() {
    const token = await TokenService.getBearerToken();

    if (token) {
      this.token = token;
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    return token;
  }

  mountRequestInterceptor() {
    axios.interceptors.request.use(
      async (request) => {
        if (!this.token) {
          await this.setHeader();
        }
        return {
          ...request,
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
      },
      (error) => Promise.reject(error),
    );
  }

  mount401Interceptor() {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.request && error.request.status === 401) {
          this.token = null;
          await this.setHeader();
          return axios.request(error.config);
        }
        throw error;
      },
    );
  }

  login(params) {
    return this.request.get('1', {
      params,
    }).then(({ data }) => data);
  }

  fetchUser(params) {
    return this.request.get('7', {
      params,
    }).then(({ data }) => data);
  }

  getGeneralParameters() {
    return this.request.get('61').then(({ data }) => data);
  }

  getExcursion(params) {
    return this.request.get('10', {
      params,
    }).then(({ data }) => data && data[0]);
  }

  getTicketTypes(params) {
    return this.request.get('25', {
      params,
    }).then(({ data }) => data);
  }

  getLanguages(params) {
    return this.request.get('14', {
      params,
    }).then(({ data }) => data);
  }

  getBarcode(bookNr) {
    return this.request
      .get(`barcode?bcid=qrcode&text=${bookNr}&includeText&scale=4`, {
        responseType: 'arraybuffer',
      }).then(({ data }) => data);
  }

  fetchPrice(params) {
    return this.request.get('12', {
      params,
    }).then(({ data }) => data);
  }

  fetchWaypays(params) {
    return this.request.get('28', {
      params,
    }).then(({ data }) => data);
  }

  fetchPickupExceptions(params) {
    return this.request.get('73', {
      params,
    }).then(({ data }) => data);
  }

  fetchTickets(params) {
    return this.request.get('30', {
      params,
    }).then(({ data }) => data);
  }

  fetchNextTicket(params) {
    return this.request.get('26', {
      params,
    }).then(({ data }) => (data && data[0] ? `${data[0].Ord || ''}${data[0].Number}` : null));
  }

  fetchDefaultSalesman(params) {
    return this.request.get('6', {
      params,
    }).then(({ data }) => (data && data[0] ? data.find(({ B2C }) => B2C === 1) : null));
  }

  fetchPaymentClientToken(waypay) {
    return this.request.get(`waypay/${waypay}`).then(({ data }) => data);
  }

  getDriversInExcurs(params) {
    return this.request.get('81', {
      params,
    }).then(({ data }) => data);
  }

  bookReservation(params) {
    return this.request.post('13', params).then(({ data }) => data);
  }

  editReservations(params) {
    return this.request.put('62', params).then(({ data }) => data);
  }

  getExcursions(params) {
    return this.request.get('9', {
      params,
    }).then(({ data }) => data);
  }

  getReservation(params) {
    return this.request.get('11', {
      params,
    }).then(({ data }) => data);
  }

  async getReservations(params) {
    return this.request.get('18', {
      params,
    }).then(({ data }) => {
      const aggregateOnExcurs = {};

      data.data
        .map(({ Date, ...rest }) => ({
          Date: moment(Date).format('YYYY-MM-DD'),
          ...rest,
        }))
        .forEach(({ ExcursCode, ...row }) => {
          const key = `${row.Date}${ExcursCode}`;

          if (aggregateOnExcurs[key]) {
            aggregateOnExcurs[key] = {
              ...aggregateOnExcurs[key],
              Adults: aggregateOnExcurs[key].Adults + row.CombinedAdults,
              Children: aggregateOnExcurs[key].Children + row.CombinedChildren,
              Infants: aggregateOnExcurs[key].Infants + row.Infants,
              Pax: aggregateOnExcurs[key].Pax + row.Pax,
              Price: aggregateOnExcurs[key].Price + row.Price,
              Cash: aggregateOnExcurs[key].Cash + row.Cash,
              Card: aggregateOnExcurs[key].Card + row.Card,
            };
          } else {
            aggregateOnExcurs[key] = {
              ...row,
              Adults: row.CombinedAdults,
              Children: row.CombinedChildren,
              Infants: row.Infants,
            };
          }
        });

      return {
        ...data,
        data: Object.values(aggregateOnExcurs)
          .sort(({ Date: d1 }, { Date: d2 }) => moment(d1, 'YYYY-MM-DD').diff(moment(d2, 'YYYY-MM-DD'))),
      };
    });
  }

  getReservationList(params) {
    return this.request.get('15', {
      params,
    }).then(({ data }) => data);
  }
}

export default new API();
