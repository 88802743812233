import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import { createI18n } from 'vue-i18n/index.mjs';
import VueQrcodeReader from 'vue-qrcode-reader';
import App from './App.vue';
import store from './store';
import router from './router';

import en from './locales/en.json';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
  },
});

router.beforeEach(async (to, from, next) => {
  const { isLoggedIn } = store.getters;

  if (to.name === 'Profile' && !isLoggedIn) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

const app = createApp(App)
  .use(store)
  .use(IonicVue)
  .use(router)
  .use(VueQrcodeReader)
  .use(i18n);

router.isReady().then(() => {
  app.mount('#app');
});
