import axios from 'axios';
import TokenService from './oauth';

class API {
  constructor() {
    this.ip = process.env.VUE_APP_MULTI_PRINT_IP;

    this.token = null;

    this.request = axios;

    this.mountRequestInterceptor();
    this.mount401Interceptor();
  }

  async setHeader() {
    const token = await TokenService.getBearerToken();

    if (token) {
      this.token = token;
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    return token;
  }

  mountRequestInterceptor() {
    axios.interceptors.request.use(
      async (request) => {
        if (!this.token) {
          await this.setHeader();
        }
        return {
          ...request,
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
      },
      (error) => Promise.reject(error),
    );
  }

  mount401Interceptor() {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.request && error.request.status === 401) {
          this.token = null;
          await this.setHeader();
          return axios.request(error.config);
        }
        throw error;
      },
    );
  }

  sendEmail({ id, ...data }) {
    return axios({
      url: `${this.ip}/email/${id}`,
      method: 'POST',
      data,
    }).then(({ data: d }) => d);
  }
}

export default new API();
