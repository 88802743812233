<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="white">
        <ion-buttons
          slot="start">
          <a
            :href="logoLink">
            <img
              class="hidden-sm-down"
              :height="150"
              :alt="generalParameters.DetailText1"
              :src="generalParameters.Logo"/>
            <img
              class="hidden-sm-up"
              :height="80"
              :alt="generalParameters.DetailText1"
              :src="generalParameters.Logo"/>
          </a>
        </ion-buttons>

        <ion-title v-if="user && user.Salesperson">
          <ion-button fill="outline">
            {{ user.Salesperson }}
          </ion-button>
        </ion-title>

        <ion-buttons slot="end" class="text-medium">
          <ion-button
            v-for="(link, name) in navbarLinks"
            :href="link"
            :key="name"
            style="font-size: 20px"
            class="ion-text-capitalize hidden-sm-down">
            {{ name }}
          </ion-button>

          <ion-menu-button
            v-if="navbarLinks"
            class="hidden-sm-up"
            :auto-hide="false"
            menu="sidenav"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-menu
      v-if="navbarLinks"
      side="start"
      menu-id="sidenav"
      content-id="sidenav-toggle">
      <ion-list>
        <ion-item
          v-for="(link, name) in navbarLinks"
          :href="link"
          :key="name">
          {{ name }}
        </ion-item>
      </ion-list>
    </ion-menu>

    <ion-content
      v-show="!isScanning">
      <ion-router-outlet></ion-router-outlet>
    </ion-content>

    <ion-toolbar>
      <ion-buttons class="text-medium">
        <ion-button
          v-for="(link, name) in footerLinks"
          target="_blank"
          :href="link"
          :key="name"
          class="ion-text-capitalize">
          {{ $t('nav.' + name) }}
        </ion-button>

        <ion-button
          v-for="(value, name) in companyInfo"
          :key="name"
          class="ion-text-capitalize">
          {{ $t('fields.' + name) }}:
          {{ value }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-page>
</template>

<script>
import {
  IonPage, IonRouterOutlet, IonToolbar, IonButton, IonHeader, IonList, IonMenu,
  IonContent, isPlatform, IonMenuButton, IonButtons, IonTitle, IonItem,
} from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import {
  chevronBackOutline as backIcon,
} from 'ionicons/icons';
import { mapActions, mapGetters, mapState } from 'vuex';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

export default {
  components: {
    IonPage,
    IonRouterOutlet,
    IonToolbar,
    IonContent,
    IonButton,
    IonHeader,
    IonMenuButton,
    IonMenu,
    IonButtons,
    IonTitle,
    IonList,
    IonItem,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    return {
      route,
      router,
    };
  },
  data() {
    return {
      backIcon,
      logoLink: process.env.VUE_APP_LOGO_LINK,
      footerLinks: {
        privacy: process.env.VUE_APP_PRIVACY_LINK,
        tos: process.env.VUE_APP_TERMS_LINK,
      },
      companyInfo: {
        vat: process.env.VUE_APP_COMPANY_VAT,
        companyName: process.env.VUE_APP_COMPANY_NAME,
      },
    };
  },
  computed: {
    ...mapState(['isScanning', 'user', 'generalParameters']),
    ...mapGetters(['isPartner']),
    navbarLinks() {
      const links = process.env.VUE_APP_NAVBAR_LINKS
        ? JSON.parse(process.env.VUE_APP_NAVBAR_LINKS)
        : null;

      return links;
    },
  },
  methods: {
    ...mapActions(['setScanningState', 'fetchUser']),
    goBack() {
      this.router.go(-1);
    },
    stopScan() {
      if (isPlatform('capacitor')) {
        this.setScanningState(false);
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
      }
    },
  },
  mounted() {
    if (this.isPartner) {
      this.fetchUser({
        CODE: this.user.UserId,
      });
    }
  },
};
</script>
